var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',[_c('v-text-field',{attrs:{"label":"Nombre","clearable":"","autocomplete":"given-name","hide-details":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('v-text-field',{attrs:{"label":"Apellidos","autocomplete":"family-name","clearable":"","hide-details":""},model:{value:(_vm.item.last_name),callback:function ($$v) {_vm.$set(_vm.item, "last_name", $$v)},expression:"item.last_name"}}),(_vm.main)?_c('v-text-field',{attrs:{"label":"Teléfono","type":"tel","autocomplete":"tel","clearable":"","hint":"Por si necesitamos contactaros :)"},model:{value:(_vm.item.phone),callback:function ($$v) {_vm.$set(_vm.item, "phone", $$v)},expression:"item.phone"}}):_vm._e(),(_vm.main)?_c('v-textarea',{attrs:{"rows":"1","auto-grow":"","label":"Dirección postal","autocomplete":"street-address","clearable":"","hint":"Para mandarte la invitación"},model:{value:(_vm.item.address),callback:function ($$v) {_vm.$set(_vm.item, "address", $$v)},expression:"item.address"}}):_vm._e(),_c('v-select',{staticClass:"mt-3",attrs:{"items":[
        { text: 'Sí 🥳', value: 'Y' },
        { text: 'No 😔', value: 'N' },
        { text: 'Aún no lo sé', value: 'Aún no sé' },
      ],"label":"¿Vienes a la boda?","hide-details":""},model:{value:(_vm.item.coming),callback:function ($$v) {_vm.$set(_vm.item, "coming", $$v)},expression:"item.coming"}}),(_vm.item.coming == 'Y' || _vm.item.coming == 'Aún no sé')?_c('div',[(_vm.item.coming != 'N')?_c('v-select',{staticClass:"mt-3",attrs:{"items":[
          { text: 'Sí', value: 'Y' },
          { text: 'No', value: 'N' },
        ],"hide-details":"","label":"¿Alguna alergía / restriccón alimentaria?"},model:{value:(_vm.item.has_alergias),callback:function ($$v) {_vm.$set(_vm.item, "has_alergias", $$v)},expression:"item.has_alergias"}}):_vm._e(),_c('v-expand-transition',[(_vm.item.coming != 'N' && _vm.item.has_alergias == 'Y')?_c('v-text-field',{staticClass:"mt-3",attrs:{"auto-grow":"","label":"¿Cuál?","placeholder":"Alergias, intolerancias, embarazos...","clearable":"","hide-details":""},model:{value:(_vm.item.alergias),callback:function ($$v) {_vm.$set(_vm.item, "alergias", $$v)},expression:"item.alergias"}}):_vm._e()],1),(_vm.item.coming != 'N')?_c('v-select',{staticClass:"mt-3",attrs:{"items":[
          { text: 'Sí', value: 'Y' },
          { text: 'No gracias', value: 'N' },
          { text: 'Aún no lo sé', value: 'Aún no sé' },
        ],"hide-details":"","label":"¿Usarás el autobús?"},model:{value:(_vm.item.bus),callback:function ($$v) {_vm.$set(_vm.item, "bus", $$v)},expression:"item.bus"}}):_vm._e(),(_vm.item.bus == 'Y')?_c('div',{staticStyle:{"margin-left":"20px"}},[_c('v-checkbox',{attrs:{"hide-details":"","label":"Iglesia -> Finca"},model:{value:(_vm.item.bus1),callback:function ($$v) {_vm.$set(_vm.item, "bus1", $$v)},expression:"item.bus1"}}),_c('v-checkbox',{attrs:{"hide-details":"","label":"Finca -> Madrid (ubicación por determinar)"},model:{value:(_vm.item.bus2),callback:function ($$v) {_vm.$set(_vm.item, "bus2", $$v)},expression:"item.bus2"}})],1):_vm._e()],1):_vm._e(),(_vm.item.coming != undefined)?_c('div',{staticStyle:{"margin-top":"20px"}},[_c('v-textarea',{attrs:{"auto-grow":"","rows":"1","label":"Comentarios / mensaje","clearable":"","hint":"¿Algo que añadir? Habla ahora o..."},model:{value:(_vm.item.message),callback:function ($$v) {_vm.$set(_vm.item, "message", $$v)},expression:"item.message"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }