<template>
  <div>
    <!--<v-card-title>Item1</v-card-title> -->
    <v-form>
      <v-text-field
        label="Nombre"
        clearable
        v-model="item.name"
        autocomplete="given-name"
        hide-details
      ></v-text-field>
      <v-text-field
        label="Apellidos"
        autocomplete="family-name"
        clearable
        v-model="item.last_name"
        hide-details
      ></v-text-field>

      <v-text-field
        v-if="main"
        label="Teléfono"
        type="tel"
        autocomplete="tel"
        clearable
        hint="Por si necesitamos contactaros :)"
        v-model="item.phone"
      ></v-text-field>

      <v-textarea
        v-if="main"
        rows="1"
        auto-grow
        label="Dirección postal"
        autocomplete="street-address"
        clearable
        v-model="item.address"
        hint="Para mandarte la invitación"
      ></v-textarea>

      <!-- coming-->
      <v-select
        class="mt-3"
        v-model="item.coming"
        :items="[
          { text: 'Sí 🥳', value: 'Y' },
          { text: 'No 😔', value: 'N' },
          { text: 'Aún no lo sé', value: 'Aún no sé' },
        ]"
        label="¿Vienes a la boda?"
        hide-details
      ></v-select>

      <div v-if="item.coming == 'Y' || item.coming == 'Aún no sé'">
        <!-- alergias -->
        <v-select
          v-if="item.coming != 'N'"
          class="mt-3"
          v-model="item.has_alergias"
          :items="[
            { text: 'Sí', value: 'Y' },
            { text: 'No', value: 'N' },
          ]"
          hide-details
          label="¿Alguna alergía / restriccón alimentaria?"
        >
        </v-select>

        <v-expand-transition>
          <v-text-field
            class="mt-3"
            auto-grow
            v-if="item.coming != 'N' && item.has_alergias == 'Y'"
            label="¿Cuál?"
            placeholder="Alergias, intolerancias, embarazos..."
            clearable
            v-model="item.alergias"
            hide-details
          ></v-text-field>
        </v-expand-transition>

        <!-- bus -->
        <!--note: we were using "null" for "aun no lo se", but that was preventing the form from being sent. Need to validate after changing to "Aun no lo se" value things works downstream -->
        <v-select
          v-if="item.coming != 'N'"
          class="mt-3"
          v-model="item.bus"
          :items="[
            { text: 'Sí', value: 'Y' },
            { text: 'No gracias', value: 'N' },
            { text: 'Aún no lo sé', value: 'Aún no sé' },
          ]"
          hide-details
          label="¿Usarás el autobús?"
        >
        </v-select>

        <div v-if="item.bus == 'Y'" style="margin-left: 20px">
          <v-checkbox
            hide-details
            v-model="item.bus1"
            label="Iglesia -> Finca"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="item.bus2"
            label="Finca -> Madrid (ubicación por determinar)"
          ></v-checkbox>
        </div>

        <!-- <v-text-field v-if="main" label="Email" :rules="emailRules" clearable v-model="item.email"></v-text-field> -->
      </div>
      <div style="margin-top: 20px" v-if="item.coming != undefined">
        <v-textarea
          auto-grow
          rows="1"
          label="Comentarios / mensaje"
          clearable
          v-model="item.message"
          hint="¿Algo que añadir? Habla ahora o..."
        ></v-textarea>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: { name: "", last_name: "", completed: false },
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..{2,5}/.test(v) || "E-mail must be valid",
      ],
    };
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    main: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      deep: true,
      handler(v) {
        //this checks for values on mandatory fields, and updates the `completed` field accordingly

        let mandatory_values = [v.name, v.last_name, v.coming]; //these are always mandatory
        if (v.coming == "N") {
          //if not coming, we don't add any other mandatory field
        } else {
          //if coming, we need these
          mandatory_values = [...mandatory_values, v.has_alergias, v.bus];

          //if coming + has_alergias, then we need the details on the alergias.
          if (v.has_alergias == "Y") {
            //if has alergias, the details become mandatory
            mandatory_values = [...mandatory_values, v.alergias];
          }
        }

        //confirmed for Fati we don't want to make these mandatory
        /*if (this.main) {
          mandatory_values = [...mandatory_values, v.phone, v.address];
        }*/

        //check all the mandatory values and verify they are not missing. If all ok, we set the field "completed" as true
        console.log("mandatory_values", mandatory_values);
        const errors = mandatory_values.filter(
          (x) => x == undefined || x == "" || x == null
        ).length;
        console.log("errors", errors);
        if (errors > 0) {
          v.completed = false;
        } else {
          v.completed = true;
        }
        this.item = v;
      },
    },
    item: {
      deep: true,
      handler(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {},
  mounted() {
    this.item = this.value;
  },
};
</script>

<style lang="scss" scoped></style>
